import './GoldenOffer.scss'
import Timer from '../Timer/Timer';
import PlainLoader from '../Loaders/PlainLoader/PlainLoader';

export interface IGoldenOffer {
  title: string;
  name: string;
  price: number;
  textBelowPrice: string;
  deliveryTime: number;
  currency: '€' | '$';
  isLoading?: boolean;

  claimText: string;
  onClaim: () => void;
}

function GoldenOffer(props: IGoldenOffer) {
  return (
      <div className="golden-offer">
        <h3>{props.title}</h3>
        <br />
        <div className='golden-offer__header'>
          <div className='golden-offer__header__price-description'>
            <span className='golden-offer__header__price-description__name'>{props.name}</span>

            <h2 className='golden-offer__header__price-description__month-price'>{props.price}{props.currency}/month</h2>

            <h3 className='golden-offer__header__price-description__text-below'>{props.textBelowPrice}</h3>
          </div>
          <div className='golden-offer__header__delivery'>
            <div className="golden-offer__header__delivery__text">
              Delivery within
            </div>

            <div className='golden-offer__header__delivery__time'>{props.deliveryTime}hours</div>
          </div>
        </div>

        <button
          className='golden-offer__claim-offer'
          onClick={props.onClaim}
          disabled={props.isLoading}
        >
          { props.isLoading
            ? <div className='golden-offer__claim-offer__loader'>
              <PlainLoader size='1.5rem' color='transparent' />
            </div> 
            : <span>{props.claimText}</span>
          }
        </button>
      </div>
  );
}

export default GoldenOffer;
