import { useNavigate } from 'react-router-dom';
import VipServicesHoc from '../../../components/Pages/VipServicesHoc/VipServicesHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function VideoEditors() {
  const navigate = useNavigate();

  useEffect(() => { 
    mixpanel.track('vip_video_editor_page')
    }, [])


  return (
    <VipServicesHoc
      title='Professional Video Editors'
      imageUrl="/images/laptop-jobs/video-editor.png"
      textBelow={`Have your videos produced by the best in the business. Get edited videos, animated quotes, cover images & social media copy for each recording within 48 hours.`}
      onButtonClick={() => navigate('/vip-services/support')}
    />
  );
}

export default VideoEditors;
