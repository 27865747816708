// import  { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import BetweenStagesHoc from '../../components/Pages/BetweenStagesHoc/BetweenStagesHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const CouchOffer = () => {
    const navigate = useNavigate();

    useEffect(() => { 
      mixpanel.track('couch_offer_page')
     }, [])

    return (
      <BetweenStagesHoc
        imageUrl='/images/backgrounds/bg9.png'
        title={'It’s like having a coach \nwho guides you \nevery step of the way.'}
        subTitle={'\n\n'}
        buttonText='I’m in!'
        onButtonClick={() => navigate('/vip-paywall')}
      />
    );
};

export default CouchOffer;
