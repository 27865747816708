import { useNavigate } from 'react-router-dom';
import VipServicesHoc from '../../../components/Pages/VipServicesHoc/VipServicesHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function Support() {
  const navigate = useNavigate();

  useEffect(() => { 
    mixpanel.track('vip_support_page')
    }, [])


  return (
    <VipServicesHoc
      title='Ongoing Support and Feedback'
      imageUrl="/images/laptop-jobs/support.png"
      textBelow={`Receive continuous encouragement & input to maintain your motivation & stay focused on achieving your goals.`}
      onButtonClick={() => navigate('/couch-offer')}
    />
  );
}

export default Support;
