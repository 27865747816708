import './AppDownloadButton.scss';
import Icon from '../../Icon/Icon';
import { useMemo } from 'react';
import oneLinkClient from '../../../../sdk-clients/oneLink';
import mixpanel from 'mixpanel-browser';

export interface IAppDownloadButton {
  appUrl?: string;
  icon?: string;
  title: string;
  text: string;
}

function AppDownloadButton(props: IAppDownloadButton) {
  const getUrl = oneLinkClient.generateOneLinkDownloadLink;

  const downloadUrl = useMemo(() => props.appUrl ?? localStorage.getItem('download-link') ?? getUrl(), [props.appUrl]);
  const downloadApp = () => {
    window.open(downloadUrl, '_blank')

    mixpanel.track('download_selected', {
      'version': 2.0,
      'title': props.text
    })
}
  return (
    <button className="app-download-button" onClick={downloadApp}>
      { props.icon && <Icon name={props.icon} /> }
      <div className='app-download-button__right-block'>
        <p className='app-download-button__right-block__top'>{props.title}</p>
        <p className='app-download-button__right-block__bottom'>{props.text}</p>
      </div>
    </button>
  );
}

export default AppDownloadButton;
