import { useNavigate } from 'react-router-dom';
import BinaryQuizHoc from '../../../components/Pages/BinaryQuizHoc/BinaryQuizHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

function HaveNoTime() {
  const navigate = useNavigate();
  
  useEffect(() => { 
    mixpanel.track('have_no_time_page')
   }, [])


  function onAnswer(answer: boolean) {
    mixpanel.track('have_no_time_selected', {
      'version': 2.0,
      'answer': answer ? 1 : 0
    });

    navigate('/sounds-like-full-time-job');
  }

  return (
    <BinaryQuizHoc
      label="can you relate to this statement?"
      title={`"My schedule is too hectic to produce content regularly"`}
      image={{ url: '/images/backgrounds/thinking-afro-american.png' }}
      onAnswer={onAnswer}
    />
  );
}

export default HaveNoTime;
