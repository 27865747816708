import { useNavigate } from 'react-router-dom';
import BinaryQuizHoc from '../../../components/Pages/BinaryQuizHoc/BinaryQuizHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

function HaveNotEditingSkills() {
  const navigate = useNavigate();
  
  useEffect(() => { 
    mixpanel.track('have_not_editing_skill_page')
   }, [])

  function onAnswer(answer: boolean) {
    mixpanel.track('have_not_editing_skill_selected', {
      'version': 2.0,
      'answer': answer ? 1 : 0
    });

    navigate('/have-no-time');
  }

  return (
    <BinaryQuizHoc
      label="can you relate to this statement?"
      title={`"I feel lost without the skills of video editing."`}
      image={{ url: '/images/backgrounds/no-editing-skills.png' }}
      onAnswer={onAnswer}
    />
  );
}

export default HaveNotEditingSkills;
