import GoBack from '../../UI/Buttons/GoBack/GoBack';
import './VipServicesHoc.scss'

export interface IWeHelpWithHoc {
  title: string;
  imageUrl: string;
  textBelow: string;
  onButtonClick(): void;
}

function VipServicesHoc(props: IWeHelpWithHoc) {
  return (
    <div className='vip-services-hoc page fade-in'>
      <header className='vip-services-hoc__header'>
          <GoBack />
          <a href="/"><img className='logo' src="/images/logo.svg" alt="Logo"/></a>
          <div></div>
      </header>

      <main className='vip-services-hoc__main'>
        <div className="vip-services-hoc__main__top">
          <h3 className='vip-services-hoc__main__top__label'>VIP SERVICE</h3>
          <h2 className='vip-services-hoc__main__top__title'>{props.title}</h2>
        </div>

        <div className='vip-services-hoc__main__main-content'>
          <div
            className="vip-services-hoc__main__main-content__image"
          >
            <img
              className="vip-services-hoc__main__main-content__image__img-tag"
              src={props.imageUrl}
            />
          </div>
        </div>
      </main>

      <footer className='vip-services-hoc__footer'>
        <div className="vip-services-hoc__footer__text">
          <span>{props.textBelow}</span>
        </div>

        <br />

        <button
          className='vip-services-hoc__footer__button'
          onClick={props.onButtonClick}
        >
          See next
        </button>
      </footer>
    </div>
  );
}

export default VipServicesHoc;
