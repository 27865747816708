import { useNavigate } from 'react-router-dom';
import BetweenStagesHoc from '../../components/Pages/BetweenStagesHoc/BetweenStagesHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

function TakeBrandNextLevel() {
  const navigate = useNavigate()
  
  useEffect(() => void mixpanel.track('take_brand_new_level_page'), []);

  return (
    <BetweenStagesHoc
      imageUrl='/images/backgrounds/bg7.png'
      title='Excellent!'
      subTitle={'Take your personal brand to the next \nlevel with our dedicated team.'}
      buttonText='Continue'
      onButtonClick={() => navigate('/vip-services/content-manager')}
    />
  );
}

export default TakeBrandNextLevel;
