import './VipPaywall.scss';
import Cross from '../../components/UI/Buttons/Cross/Cross';
import PackagePropositions, { IPackagePropositions } from '../../components/UI/PackagePropositions/PackagePropositions';
import Review, { IReview } from '../../components/UI/Review/Review';
import { useEffect, useRef, useState } from 'react';
import constants, { EVENTS } from '../../utils/constants';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import Carousel from '../../components/UI/Carousel/Carousel';
import TeamMemberCard, { ITeamMemberCard } from './TeamMemberCard/TeamMemberCard';
import ContentProductionCard, { IContentProductionCard } from './ContentProductionCard/ContentProductionCard';
import GoldenOffer from '../../components/UI/GoldenOffer/GoldenOffer';

const VipPaywall = () => {
    const navigate = useNavigate();
  
    useEffect(() => { 
      mixpanel.track('vip_paywall_page')
      }, [])
  

    const [isCrossVisible, setCrossVisibility] = useState(false);

    const afterOfferRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    setCrossVisibility(true);
                    observer.disconnect();
                }
            },
            { threshold: 1 }
        );

        observer.observe(afterOfferRef.current!);

        return () => observer.disconnect();
    }, []);

    function gotoPaywall() {
        mixpanel.track('vip_back_to_paywall');
        navigate('/paywall');
    }

    const handleCrossButton = () => {
        mixpanel.track('vip_back_to_paywal_cross');
        gotoPaywall();
    }

    function onSpecialOfferClaim() {
        mixpanel.track('book_a_call_select');

        window.open('https://calendly.com/wois/free-consultation-us', '_blank');
    }

    const exclusivePropositions: IPackagePropositions['propositions'] = [
        {
            icon: 'lamp',
            title: 'Creating a personalized content strategy',
        },
        {
            icon: 'ribbon-plus',
            title: 'Running a content plan',
        },
        {
            icon: 'flag',
            title: 'Daily guidance & inspiration',
        },
        {
            icon: 'voice',
            title: 'Producing premium video content',
        },
        {
            icon: 'smartphone',
            title: '24h support & feedback',
        },
    ];

    const teamMembers: ITeamMemberCard[] = [
        {
            imageUrl: '/images/team-members/content-manager.png',
            role: 'Content manager',
            title: 'Your main contact person who will guide & motivate you on daily basis',
            keyTasks: ['Content strategy', 'Content plan', 'Daily content ideas', 'Support & motivation'],
        },
        {
            imageUrl: '/images/team-members/video-editor.png',
            role: 'Video editors',
            title: 'Skilled video editors who can provide tailored content.',
            keyTasks: ['Professional video editing', 'Music & Sound design', 'Text & Video animation', 'Storytelling'],
        },
    ];

    const contentProductionCards: IContentProductionCard[] = [
        {
            title: 'Engaging Videos',
            subtitle: 'We polish your videos and add karaoke-style closed captions in any language to ensure they stand out.',
            imageUrl: '/images/content-production/engaging-videos.png',
        },
        {
            title: 'Cover Design',
            subtitle: 'We select the best frame from your videos and edit it to match your personal brand’s aesthetic.',
            imageUrl: '/images/content-production/cover-design.png',
        },
        {
            title: 'Cross-Platform Posts',
            subtitle: 'We craft engaging social posts, complete with a compelling title, description and hashtags.',
            imageUrl: '/images/content-production/cross-platform-posts.png',
        },
    ];

    const reviews: IReview[] = [
        {
            comment: 'Wois is like Linkedin meets Tiktok… Easy to use and lots of smart talk to have',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/cory-warfield.png',
                name: 'Cory Warfield',
                label: 'LinkedIn Top Voice',
            },
        },
        {
            comment: 'Just by talking about startup culture and entrepreneurship on Wois, I found an investor for my startup.',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/tonia-samsanova.png',
                name: 'Tonia Samsonova',
                label: 'Founder of Exactly.ai',
            },
        },
        {
            comment: 'Easiest place to build a network and my personal brand just by being myself.',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/shahab-anbarjafari.png',
                name: 'Shahab Anbarjafari',
                label: 'Professor, Chief Data Scientist at PwC',
            },
        },
    ];

    return (
        <div className='page vip-paywall-page fade-in'>
            <div className={['vip-paywall-page__cross', isCrossVisible ? '' : 'display-none' ].join(' ')}>
                <Cross onClick={handleCrossButton} />
            </div>

            <header className='vip-paywall-page__header'>
                <div></div>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <div></div>
            </header>

            <main className='vip-paywall-page__main'>
                <h3 className='vip-paywall-page__main__title'>
                    VIP SERVICE
                </h3>

                <h2>
                    <span className='text-golden'>Your Brand</span> with Expert
                    <br />
                    Guidance, Motivation, and
                    <br />
                    Content Creation.
                </h2>

                <img
                    className='vip-paywall-page__main__top-image'
                    src="/images/backgrounds/bg5.png"
                />

                <div className='vip-paywall-page__main__exclusive-proposition'>
                    <PackagePropositions
                        customTitle={<><h2>Our dedicated team supports you with:</h2> <br /></>}
                        propositions={exclusivePropositions}
                    />
                </div>

                <br />

                <h2>
                    Your team consist of
                    <br />
                    leading industry <span className='text-golden'>experts</span>
                </h2>

                <br />
                {teamMembers.map(TeamMemberCard)}

                <br />


                <div className='vip-paywall-page__main__content-production'>
                    <h2>Content Production</h2>
                    <h3>Get curated & engaging social media content for all platforms</h3>

                    <br />
                    <br />

                    {contentProductionCards.map(ContentProductionCard)}
                </div>

                <br />
                <br />

                <h2>
                    What our clients
                    <br />
                    are saying
                </h2>

                <br />
                <br />

                <div className="vip-paywall-page__main__reviews">
                    <Carousel
                        delay={1500}
                        items={reviews.map((review) => Review({ ...review, styles: { height: '11rem', margin: 0 } }))}
                        cardStyles={{ width: '100%' }}
                        swipable
                        wrapperTransparent
                    />
                </div>

                <br />
                <br />

                <div className='vip-paywall-page__main__focus-what-matters'>
                    <h2 className='vip-paywall-page__main__focus-what-matters__title'>
                        Focus on what matters,
                        <br />
                        we do the heavy lifting!
                    </h2>
                </div>

                <div className="vip-paywall-page__main__special-offer">
                    <GoldenOffer
                        title="Get high-quality video content on consistent basis"
                        name="VIP Service"
                        price={1850}
                        textBelowPrice='including all-in-one platform'
                        deliveryTime={48}
                        currency='$'

                        claimText='Book Your Free Call'
                        onClaim={onSpecialOfferClaim}
                    />
                </div>

                <div ref={afterOfferRef} />

                <div className='vip-paywall-page__main__changed-mind'>
                    <h2>I changed my mind</h2>
                    <h3>let’s stick with the self-service for now</h3>
                </div>

                <br />
                <br />
            </main>
            
            <footer className='vip-paywall-page__footer'>
                <button
                    onClick={gotoPaywall}
                >
                    Return to Self-Service Offer
                </button>

                <div className='vip-paywall-page__footer__rights'>
                    <div className='vip-paywall-page__footer__rights__copyright'>© 2024 Wois app</div>

                    <div className='vip-paywall-page__footer__terms'>
                        <a href='https://wois.io/terms' target='_blank'>Terms and conditions</a>
                    </div>

                    <div>
                        <a href='https://wois.io/privacy' target='_blank'>Privacy Policy</a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default VipPaywall;
