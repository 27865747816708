import './ContentProductionCard.scss';

export interface IContentProductionCard {
  title: string;
  subtitle: string;
  imageUrl: string;
}

function ContentProductionCard(props: IContentProductionCard) {
  return (
    <div className='content-production-card'>
      <div className="content-production-card__text">
        <h1
            className='content-production-card__text__title'
        >
          {props.title}
        </h1>

        <h2
            className='content-production-card__text__sub-title'
        >
          {props.subtitle}
        </h2>
      </div>
      <img
        src={props.imageUrl}
        className="content-production-card__image"
      />
    </div>
  );
}

export default ContentProductionCard;
