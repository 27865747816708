import React from 'react';
import './App.scss';
import {BrowserRouter} from "react-router-dom";
import {useRoutes} from "../../navigation/routes";
import mixpanel from 'mixpanel-browser';
import oneLinkClient from '../../sdk-clients/oneLink';
import constants from '../../utils/constants';

localStorage.setItem('download-link', oneLinkClient.generateOneLinkDownloadLink());

const App: React.FC = () => {
    const routes = useRoutes()

    mixpanel.init('badea4deca72ac5684a2eae5c5a8daa9',
     {debug: constants.MIXPANEL_IS_TEST,
    track_pageview: true,
    persistence: 'localStorage'});
     
    return (
        <BrowserRouter>
            {routes}
        </BrowserRouter>
    );
}

export default App;