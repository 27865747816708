import './PayNow.scss';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import confettiAnimation from './confettiAnimation';
import WindowSpawner from '../../utils/WindowSpawner';
import constants, { EVENTS } from '../../utils/constants';
import paymentsService from '../../services/payments';
import SpecialOfferAd from '../../components/UI/SpecialOffer/SpecialOffer';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';

interface IGetPaymentLink {
  email: string;
  userId: string
}

async function getPaymentLink(user: IGetPaymentLink): Promise<string> {
  const stripeCustomer = await paymentsService.createStripeCustomer({
    email: user.email,
    metadata: { customer_user_id: user.userId },
  });

  const link = await paymentsService.createPaymentLink({
    stripeCustomer: { id: stripeCustomer.id },
    mode: 'subscription',
    uiMode: 'hosted',
    lineItems: [
      {
        price: constants.SUBSCRIPTION_STRIPE_PRICE_ID,
        quantity: 1,
      },
    ],
    discounts: [
      { promotion_code: constants.WOIS2024_PROMO_ID }
    ],
    metadata: {
      customer_user_id: user.userId,
    },
    urls: {
      success: window.location.origin.concat('/payment-callback?success=true'),
      cancel: window.location.origin.concat('/payment-callback'),
    },
  });

  return link;
}

function PayNow() {
  const navigate = useNavigate();
  const [paymentLink, setPaymentLink] = useState<string>();

  const [shouldShowOffer, setShouldShowOffer] = useState(false);

  useEffect(() => {
    setTimeout(() => setShouldShowOffer(true), 1000);
  }, []);

  useEffect(() => {
    const email = localStorage.getItem('email')!;
    const userId = localStorage.getItem('userId')!;

    getPaymentLink({ email, userId })
      .then(setPaymentLink);
  },[]);
  
  useEffect(() => void mixpanel.track('pay_now_page'), []);

  function payNow() {
    mixpanel.track('pay_now_select')
    onClaim(new URL(paymentLink!))
  }

  function onClaim(paymentUrl: URL) {
    const paymentWindow = new WindowSpawner('payment-window', paymentUrl, {
      popup: true,
      width: '700px',
      height: '700px',
      resizable: false,
      location: false,
      menubar: false,
  });

    paymentWindow.open();

    paymentWindow.addMessageListener(
        (data) => {
            paymentWindow.close();

            if (data === EVENTS.PAYMENT_SUCCESS_EVENT) navigate('/welcome-aboard');
        },
        true,
    );
  }

  return (
    <div className='pay-now page'>
      <header>
          <div></div>
          <a href="/"><img className='logo' src="/images/logo.svg" alt="Logo"/></a>
          <div></div>
      </header>

      <main className='pay-now__main'>

          <br />
          <br />

          <h2>
            Your strategy is ready!
            <br />
            Now use your -50% offer.
          </h2>

          <br />
          <br />

          <div className={['pay-now__main__special-offer', !shouldShowOffer && 'display-none'].join(' ')}>
            <SpecialOfferAd
                animation='fadeInSequence'
                animationDelay='.6s'
                title=""
                claimText='Pay now'
                price={18}
                currency='$'
                discount={50}
                delay={36000}
                isLoading={!paymentLink}
                onClaim={payNow}
            />
          </div>
      </main>

      <Player
        className='pay-now__player'
        autoplay
        keepLastFrame
        src={confettiAnimation}
      />
    </div>
  );
}

export default PayNow;
