import './TeamMemberCard.scss';

export interface ITeamMemberCard {
  imageUrl: string;
  role: string;
  title: string;
  keyTasks: string[];
}

function TeamMemberCard(props: ITeamMemberCard) {
  return (
    <div className="team-member-card">
      <img className="team-member-card__image" src={props.imageUrl} />

      <div className='team-member-card__right-block'>
        <h1 className='team-member-card__right-block__role'>{props.role}</h1>
        <h2 className='team-member-card__right-block__title'>{props.title}</h2>

        <div className="team-member-card__right-block__key-tasks">
          Key tasks:
          <br />
          <br />
          <ul className='team-member-card__right-block__key-tasks__list'>
              {props.keyTasks.map((task) => <li>{task}</li>)}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TeamMemberCard;
