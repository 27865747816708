import { useNavigate } from 'react-router-dom';
import VipServicesHoc from '../../../components/Pages/VipServicesHoc/VipServicesHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function ContentManager() {
  const navigate = useNavigate();

  useEffect(() => { 
    mixpanel.track('vip_content_manager_page')
    }, [])


  return (
    <VipServicesHoc
      title='Content Manager'
      imageUrl="/images/laptop-jobs/content-manager.png"
      textBelow={`Your dedicated content manager will create a custom-made plan to keep you consistent & ensure your content is engaging.`}
      onButtonClick={() => navigate('/vip-services/video-editors')}
    />
  );
}

export default ContentManager;
