import { useEffect } from 'react';
import './ThousandsTrustUs.scss';
import GoBack from "../../components/UI/Buttons/GoBack/GoBack";
import mixpanel from 'mixpanel-browser';
import Carousel from '../../components/UI/Carousel/Carousel';
import Review, { IReview } from '../../components/UI/Review/Review';
import { useNavigate } from 'react-router-dom';

const ThousandsTrustUs = () => {
    const navigate = useNavigate();

    const reviews: IReview[] = [
        {
            comment: 'Wois is like Linkedin meets \nTiktok… Easy to use and lots of \nsmart talk to have.',
            reviewer: {
                avatarUrl: '/images/peoples/cory-warfield.png',
                name: 'Cory Warfield',
                label: 'Linkedin Top Voice',
            },
            stars: 5,
        },
        {
            comment: 'Just by talking about startup culture and entrepreneurship on Wois, I found an investor for my startup.',
            reviewer: {
                avatarUrl: '/images/peoples/tonia-samsanova.png',
                name: 'Tonia Samsonova',
                label: 'Founder of Exactly.ai',
            },
            stars: 5,
        },
        {
            comment: 'Easiest place to build a network \nand my personal brand just by being \nmyself.',
            reviewer: {
                avatarUrl: '/images/peoples/shahab-anbarjafari.png',
                name: 'Shahab Anbarjafari',
                label: 'Professor, Chief Data Scientist at PwC',
            },
            stars: 5,
        },
    ];

    useEffect(() => void mixpanel.track('thousands_trust_us_page'), []);
  
    return (
        <div className='page thousands-trust-us-page fade-in'>
            <header className='thousands-trust-us-page__header'>
                <div></div>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <img src="./images/step/step-1.svg" alt="step-1"/>
            </header>
            <main className='thousands-trust-us-page__main'>
                <h3 className='text-align-center'>
                        PERSONAL BRANDING MADE EASY
                </h3>

                <h2 className='text-align-center'>
                    Thousands of personal brand
                    <br />
                    builders trust us!
                </h2>

                <div className='thousands-trust-us-page__main__app-rating'>
                    <img
                        className="thousands-trust-us-page__main__app-rating__image"
                        src="/images/app-rating.png"
                        alt="app rating 4.7 points"
                    />
                </div>

                <Carousel
                    delay={1500}
                    items={reviews.map((review) => Review({ ...review, styles: { height: '12.5rem', margin: 0 } }))}
                    swipable
                    wrapperTransparent
                />
            </main>
            <footer className='thousands-trust-us-page__footer'>
                <button onClick={() => navigate('/goals')}>Continue</button>
            </footer>
        </div>
    );
};

export default ThousandsTrustUs;
