// temp solution

export const EVENTS = {
  PAYMENT_SUCCESS_EVENT: 'payment:success',
  PAYMENT_FAILED_EVENT: 'payment:failed',
}

const ENV = {
  WOIS_ONBOARDING_ORIGIN: 'https://test.onboarding.vois.io',
  WOIS_API_ORIGIN: 'https://test.api.vois.io',
  SUBSCRIPTION_STRIPE_PRICE_ID: 'price_1P69mkJCC79GBlfdLmZq1xI1',
  WOIS2024_PROMO_ID: 'promo_1P6uAzJCC79GBlfdnLyQl0IR',
  MIXPANEL_IS_TEST: true,
};

if (window.location.host === 'welcome-b.wois.io') {
  Object.assign(ENV, {
    WOIS_ONBOARDING_ORIGIN: 'https://onboarding.wois.io',
    WOIS_API_ORIGIN: 'https://api.wois.io',
    SUBSCRIPTION_STRIPE_PRICE_ID: 'price_1P8NfzJCC79GBlfdFaCtO3R5',
    WOIS2024_PROMO_ID: 'promo_1P8NhWJCC79GBlfdeVOztbXn',
    MIXPANEL_IS_TEST: false,
  });
}

export default ENV;
