import { useNavigate } from 'react-router-dom';
import WeHelpWithHoc from '../../../components/Pages/WeHelpWithHoc/WeHelpWithHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function WeSparkYourInspiration() {
  const navigate = useNavigate();

  useEffect(() => { 
    mixpanel.track('we_spark_your_insp_page')
   }, [])

  return (
    <div>
      <WeHelpWithHoc
        label="we spark your inspiration"
        title={`We've curated thousands personalized content ideas to keep your creativity flowing.`}
        mainContent={<img src="/images/spark-inspiration-fake-carousel.png" style={{ height: '100%', objectFit: 'contain' }} />}
        textBelow={`You'll always have something \nto talk about.`}
        buttonText="Great, see more"
        onButtonClick={() => navigate('/record-with-ease')}
      />
    </div>
  );
}

export default WeSparkYourInspiration;
