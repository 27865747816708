import { useNavigate } from 'react-router-dom';
import WeHelpWithHoc from '../../../components/Pages/WeHelpWithHoc/WeHelpWithHoc';
import mixpanel from 'mixpanel-browser';
import { useEffect} from 'react';

function RecordWithEase() {
  const navigate = useNavigate();

  useEffect(() => { 
    mixpanel.track('record_with_ease_page')
    }, [])
  

  return (
    <div>
      <WeHelpWithHoc
        label="Record with ease"
        title={'No equipment? No problem. \nAll you need is your phone.'}
        mainContent={<img src="/images/forest-man-in-phone.png" style={{ height: '100%', objectFit: 'contain' }} />}
        textBelow={'Add your notes to our teleprompter \nto keep track of your ideas.'}
        buttonText="That’s useful, see more"
        onButtonClick={() => navigate('/produce-content-in-seconds')}
      />
    </div>
  );
}

export default RecordWithEase;
