import './Paywall.scss';
import Cross from '../../components/UI/Buttons/Cross/Cross';
import PackagePropositions, { IPackagePropositions } from '../../components/UI/PackagePropositions/PackagePropositions';
import Review, { IReview } from '../../components/UI/Review/Review';
import SpecialOffer from '../../components/UI/SpecialOffer/SpecialOffer';
import { useEffect, useRef, useState } from 'react';
import PaywallOfferDeclinePopup from '../../components/UI/Popups/PaywallOfferDeclinePopup/PaywallOfferDeclinePopup';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';
import Carousel from '../../components/UI/Carousel/Carousel';
import GuideSlide, { IGuideSlide } from './GuideSlide/GuideSlide';

const PaywallView = () => {
    const navigate = useNavigate();

    const [isCrossVisible, setCrossVisibility] = useState(false);
    const [IsPaywallOfferDeclinePopupOpened, setIsPaywallOfferDeclinePopupOpened] = useState(false);

    const afterOfferRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    setCrossVisibility(true);
                    observer.disconnect();
                }
            },
            { threshold: 1 }
        );

        observer.observe(afterOfferRef.current!);

        return () => observer.disconnect();
    }, [])

    const handleCrossButton = () => {
        setIsPaywallOfferDeclinePopupOpened(true);
    }

    function onSpecialOfferClaim() {
        mixpanel.track('special_offer_claim');
        navigate('/auth');
    }

    const guideSlides: IGuideSlide[] = [
        {
            imgSrc: '/images/paywall-images/content-ideas.png',
            label: 'Content ideas',
        },
        {
            imgSrc: '/images/paywall-images/teleprompter.png',
            label: 'Teleprompter',
        },
        {
            imgSrc: '/images/paywall-images/ai-generated-videos.png',
            label: 'AI Generated Videos',
        },
        {
            imgSrc: '/images/paywall-images/animated-short-videos.png',
            label: 'Animated Short Videos',
        },
        {
            imgSrc: '/images/paywall-images/text-summaries.png',
            label: 'Text Summaries',
        },
        {
            imgSrc: '/images/paywall-images/translation-and-dubbing.png',
            label: 'Translation & Dubbing',
        },
    ];

    const exclusivePropositions: IPackagePropositions['propositions'] = [
        {
            icon: 'lamp',
            title: 'Unlimited content ideas',
        },
        {
            icon: 'ribbon-plus',
            title: 'Unlimited AI-generated videos',
        },
        {
            icon: 'flag',
            title: 'Multi-language Captions',
        },
        {
            icon: 'voice',
            title: 'Translation & Voice Dubbing',
        },
        {
            icon: 'smartphone',
            title: 'On-screen Teleprompter',
        },
    ];

    const reviews: IReview[] = [
        {
            comment: 'Wois is like Linkedin meets Tiktok… Easy to use and lots of smart talk to \nhave',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/cory-warfield.png',
                name: 'Cory Warfield',
                label: 'LinkedIn Top Voice',
            },
        },
        {
            comment: 'Just by talking about startup culture and entrepreneurship on Wois, I found an investor for my startup.',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/tonia-samsanova.png',
                name: 'Tonia Samsonova',
                label: 'Founder of Exactly.ai',
            },
        },
        {
            comment: 'Easiest place to build a network and my personal brand just by being \nmyself.',
            stars: 5,
            reviewer: {
                avatarUrl: './images/peoples/shahab-anbarjafari.png',
                name: 'Shahab Anbarjafari',
                label: 'Professor, Chief Data Scientist at PwC',
            },
        },
    ];

    return (
        <div className='page paywall-page fade-in'>
            <div className={['paywall-page__cross', isCrossVisible ? '' : 'display-none' ].join(' ')}>
                <Cross onClick={handleCrossButton} />
            </div>

            <header className='paywall-page__header'>
                <div></div>
                <a href="/"><img className='logo' src="./images/logo.svg" alt="Logo"/></a>
                <div></div>
            </header>

            <main className='paywall-page__main'>
                <h2>
                    Quit Stressing &
                    <br />
                    Master Personal Branding
                </h2>
                <br />
                <h3
                    className='paywall-page__main__easy-to-follow-guide'
                >
                    Easy-to-follow Guide
                </h3>

                <br />
                <br />
                <br />

                <div className="paywall-page__main__guide-slides">
                    <Carousel
                        delay={1500}
                        items={guideSlides.map(GuideSlide)}
                        cardStyles={{ width: 'auto' }}
                        swipable
                        wrapperTransparent
                    />
                </div>

                <div className='paywall-page__main__exclusive-proposition'>
                    <PackagePropositions
                        customTitle={
                            <h2 className='paywall-page__main__exclusive-proposition__title'>
                                Turn your phone into a
                                <br />
                                personal branding studio
                            </h2>
                        }
                        propositions={exclusivePropositions}
                    />
                </div>

                <br />

                <h2 className='text-white'>
                    Everything you need
                    <br />
                    for video-based
                    <br />
                    personal branding
                </h2>

                <br />
                <br />

                <img
                    className='paywall-page__main__video-text-comparison'
                    src="/images/charts/video-compared-text.png"
                    alt="video compared to text effectiveness chart"
                />

                <div className="paywall-page__main__video-text-gitnux-research">
                    <img
                        className='paywall-page__main__video-text-gitnux-research__quote'
                        src="/images/icons/quote-start.svg"
                    />

                    <div
                        className='paywall-page__main__video-text-gitnux-research__top-text'
                    >
                        Mobile
                        &nbsp;<span className='text-green-350'>video</span>&nbsp;
                        consumption increases by 100% every year.
                        <br />
                        <br />
                        93% of brands get new customer because of
                        &nbsp;<span className='text-green-350'>video-based</span>&nbsp;
                        content
                    </div>

                    <br />

                    <img
                        className='paywall-page__main__video-text-gitnux-research__logo'
                        src="/images/gitnux-logo.svg"
                        alt="Gitnux"
                    />
                    
                    <br />

                    <span
                        className='paywall-page__main__video-text-gitnux-research__bottom-text'
                    >
                        research by gitnux.org
                    </span>
                </div>

                <h2>
                    Trusted by thousands of
                    personal brand builders!
                </h2>

                <div className='paywall-page__main__app-rating'>
                    <img
                        className="paywall-page__main__app-rating__image"
                        src="/images/app-rating.png"
                        alt="app rating 4.7 points"
                    />
                </div>

                <br />
                <br />

                <div className="paywall-page__main__reviews">
                    <Carousel
                        delay={1500}
                        items={reviews.map((review) => Review({ ...review, styles: { height: '12.5rem', margin: 0 } }))}
                        swipable
                        wrapperTransparent
                    />
                </div>

                <div className='paywall-page__main__awards'>
                    <div className='paywall-page__main__awards__award-img-wrapper'>
                        <img src="./images/awards/award-3.svg" />
                    </div>

                    <div className="paywall-page__main__awards__center">
                        <div className='paywall-page__main__awards__award-img-wrapper'>
                            <img className='' src="./images/awards/award-1.svg" />
                        </div>

                        <div className='paywall-page__main__awards__award-img-wrapper'>
                            <img src="./images/awards/award-2.svg" />
                        </div>
                    </div>

                    <div className='paywall-page__main__awards__award-img-wrapper'>
                        <img src="./images/awards/award-4.svg" />
                    </div>
                </div>

                <h2>
                    Be proud to establish your personal brand!
                </h2>

                <br />
                <br />

                <img
                    className='paywall-page__main__establish-your-brand'
                    src="/images/paywall-images/establish-your-brand.svg"
                />

                <br />
                <br />

                <h2>
                    Get a personal webpage 
                    as a <span className='text-dase'>bonus</span>!
                </h2>

                <br />

                <img
                    className='paywall-page__main__guaranteed-best-value'
                    src="/images/paywall-images/guaranteed-best-value.svg"
                />

                <h3 className='paywall-page__main__get-custom-website'>
                    Get a custom website built just for you—your ultimate platform to showcase your insights and boost your brand!"
                </h3>

                <br />
                <br />

                <div className='paywall-page__main__personal-brand-in-5-mins'>
                    <h2>
                        Personal branding
                        <br />
                        made easy — just
                        <br />
                        5 minutes a day!
                    </h2>
                </div>

                <div className="paywall-page__main__special-offer">
                    <SpecialOffer
                        title="Gain access to our all-in-one platform"
                        price={18}
                        currency='$'
                        discount={50}
                        delay={36000}

                        onClaim={() => onSpecialOfferClaim()}
                    />
                </div>

                <div ref={afterOfferRef} />

                <br />
                <br />
            </main>
            
            <footer className='paywall-page__footer'>
                <div className="paywall-page__footer__team-needed">
                    <h2>
                        That’s not enough,
                        <br />
                        <span className='text-yellow-to-gold'>I need a team!</span>
                    </h2>

                    <h3>
                        Hire a dedicated team
                        <br />
                        to get a personalized approach and
                        <br />
                        accomplish your goals faster.
                    </h3>

                    <div className="paywall-page__footer__team-needed__employees">
                        {
                            [
                                {
                                    title: 'Personal Content Manager',
                                    image: '/images/peoples/content-manager.png',
                                },
                                {
                                    title: 'Professional Video Editor',
                                    image: '/images/peoples/video-editor.png',
                                },
                            ].map((employee) => (
                                <div className='paywall-page__footer__team-needed__employees__employee'>
                                    <img
                                        src={employee.image}
                                    />

                                    <h3
                                        className='paywall-page__footer__team-needed__employees__employee__title'
                                    >
                                        {employee.title}
                                    </h3>
                                </div>
                            ))
                        }
                    </div>

                    <div className="paywall-page__footer__team-needed__pricing">
                        <div className="paywall-page__footer__team-needed__pricing__text">
                            <h3
                                className="paywall-page__footer__team-needed__pricing__text__vip"
                            >
                                VIP Service
                            </h3>
                            <h2>$1850/month</h2>
                        </div>

                        <button
                            className='paywall-page__footer__team-needed__pricing__button'
                            onClick={() => navigate('/take-brand-next-level')}
                        >
                            <span>Learn more</span>
                        </button>
                    </div>
                </div>

                <div className='paywall-page__footer__rights'>
                    <div className='paywall-page__footer__rights__copyright'>© 2024 Wois app</div>

                    <div className='paywall-page__footer__terms'>
                        <a href='https://wois.io/terms' target='_blank'>Terms and conditions</a>
                    </div>

                    <div>
                        <a href='https://wois.io/privacy' target='_blank'>Privacy Policy</a>
                    </div>
                </div>
            </footer>

            <PaywallOfferDeclinePopup
                isOpened={IsPaywallOfferDeclinePopupOpened}
                setIsOpened={setIsPaywallOfferDeclinePopupOpened}
            />
        </div>
    );
};

export default PaywallView;
